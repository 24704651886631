/* Styles
==========================================================*/
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Fluid Typography
==========================================================*/
/* Base
==========================================================*/
html,
body {
  background: white;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.no-js .js-element {
  display: none; }

/* Typography
==========================================================*/
@font-face {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/axiforma-light.woff2") format("woff2"), url("../fonts/axiforma-light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

@font-face {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/axiforma-regular.woff2") format("woff2"), url("../fonts/axiforma-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

@font-face {
  font-family: 'Axiforma';
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../fonts/axiforma-black.woff2") format("woff2"), url("../fonts/axiforma-black..woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap; }

/* Fluid Typography
/* @styles/_mixins.scss
==========================================================*/
html {
  font-family: "Axiforma", "Roboto", sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  html {
    font-size: 14px; }
    @media screen and (min-width: 768px) {
      html {
        font-size: calc(14px + 4 * ((100vw - 768px) / 1012)); } }
    @media screen and (min-width: 1780px) {
      html {
        font-size: 18px; } }

strong {
  color: #000; }

p > a {
  @apply border-b border-dotted border-gray-700; }
  p > a:hover {
    @apply border-none; }

p {
  @apply mb-4 leading-loose text-gray-800; }

p + p {
  padding-top: 1em; }

h1 {
  @apply md:text-3xl font-normal mb-4 border-t-8 border-black pt-2 inline-block; }

h2 {
  @apply text-3xl; }

h3 {
  @apply text-xl mb-2; }

/* Fade
==========================================================*/
.fadeOutBody {
  opacity: 0;
  transition: 500ms opacity cubic-bezier(0.22, 1, 0.36, 1); }

/* Images
==========================================================*/
.singleimage img {
  width: 100%;
  min-width: 100%;
  height: auto; }

.lazysrcset-ratio {
  @apply relative; }
  .lazysrcset-ratio > img {
    @apply absolute top-0 left-0 w-full h-full block; }
    .lazysrcset-ratio > img:after {
      content: '';
      @apply block h-0 w-full; }

/* Accordion
==========================================================*/
/* Panel style */
.a-container .a-panel {
  width: 100%;
  color: #262626;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
  height: auto;
  max-height: 0;
  overflow: hidden; }

.a-container {
  position: relative; }

.a-container .a-btn:hover {
  cursor: pointer; }

/* Panel style when active */
.a-container.active .a-panel {
  opacity: 1;
  height: auto;
  max-height: 500px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.a-plus {
  position: absolute;
  right: 0;
  top: 0.4em;
  width: 1em;
  height: 1em; }

.a-plus:before,
.a-plus:after {
  content: "";
  position: absolute;
  background-color: #000;
  transition: transform 0.25s ease-out; }

/* Vertical line */
.a-plus:before {
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  margin-left: -2px; }

/* horizontal line */
.a-plus:after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  margin-top: -2px; }

.a-plus:hover {
  cursor: pointer; }

.a-container.active .a-plus:before {
  transform: rotate(90deg); }

.a-container.active .a-plus:after {
  transform: rotate(180deg); }

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.2s; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.carousel img {
  max-width: none; }

@media screen and (max-width: 1023px) {
  .slider .previous,
  .slider .next {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    border-radius: 0;
    opacity: 0;
    transform: none; }
  .slider .previous {
    left: 0; }
  .slider .next {
    right: 0; } }

@media screen and (min-width: 1024px) {
  .slider .flickity-button-icon {
    display: none; }
  .slider {
    position: relative; }
  .slider img {
    width: 100%;
    height: auto; }
  .slider .previous,
  .slider .next {
    background: none;
    border: none;
    position: absolute;
    width: 50%;
    top: 0%;
    height: 100%;
    z-index: 99999;
    transform: none; }
  .slider .previous {
    right: 0%; }
  .slider .next {
    right: 0%; }
  .slider .previous:hover {
    cursor: url("../images/arrow-left.svg"), pointer; }
  .slider .next:hover {
    cursor: url("../images/arrow-right.svg"), pointer; }
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: auto; } }

/* Carousel
==========================================================*/
@media screen and (max-width: 1023px) {
  .carousel .flickity-button {
    display: none; } }

.carousel .flickity-prev-next-button {
  background: #000;
  border-radius: 0;
  border: none;
  position: absolute;
  top: auto;
  bottom: 0;
  transform: none;
  color: #fff; }

.carousel .flickity-prev-next-button.next {
  right: 0; }

.carousel .flickity-prev-next-button.previous {
  left: auto;
  right: 2.6rem; }

.carousel .flickity-button-icon {
  top: 30%;
  left: 30%;
  width: 40%;
  height: 40%; }

/* Dots
==========================================================*/
@media screen and (max-width: 1023px) {
  .flickity-page-dots {
    bottom: 0rem; }
  .flickity-page-dots .dot {
    width: 12px;
    height: 2px;
    border-radius: 0;
    margin: 0 3px; } }

@media screen and (min-width: 1024px) {
  .flickity-page-dots .dot {
    width: 16px;
    height: 4px;
    margin: 0 4px;
    border-radius: 0; } }

.flickity-page-dots li:only-child {
  display: none; }

/* Projects
==========================================================*/
.project__title {
  @apply py-1; }

@media (min-width: 641px) {
  .project__title {
    @apply mt-4;
    color: #fff;
    background: #000;
    position: absolute;
    top: 0;
    left: 10px;
    color: #fff;
    background: #000;
    height: 30px;
    transition: 200ms all ease-out; }
  .project__title::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -10px;
    width: 10px;
    height: 33px;
    background-color: #fff;
    transform: skew(-15deg, -15deg) rotate(-15deg) translateY(0.25em);
    transition: 200ms all ease-out; }
  .projects__container__item:hover .project__title {
    color: #000;
    background: #fff;
    transform: scale(1.2);
    left: 0;
    transform-origin: center left; }
  .projects__container__item:hover .project__title::before {
    transform: scale(0.5); }
  .projects__container__item figure {
    transition: 200ms all ease-out; }
  .projects__container__item:hover figure {
    transform: scale(0.99); } }
